import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static targets = ["residentInput", "sparEnabledInput"];

  connect() {
    this.sparEnabledInputTarget.dispatchEvent(new Event("input"));
  }

  toggleResidentInput(event) {
    this.residentInputTarget.disabled = event.currentTarget.checked;
  }
}
